import { StackProps, Text, VStack } from '@chakra-ui/react'

import { PaedsEngageFields } from '../extract-paeds-form-fields'
import { bodyStyle, headerStyle } from '../paeds-layer-styles'

import { formatDate } from '@/utils/human-readable'
import { formatAddress } from '~shared/utils/human-readable'

export const PaedsEngageSentBy = ({
  data,
  ...rest
}: { data: PaedsEngageFields } & StackProps) => {
  return (
    <VStack
      {...bodyStyle}
      justifySelf="end"
      alignItems="flex-start"
      spacing={0}
      {...rest}
    >
      <Text {...headerStyle} textTransform="unset">
        <b>{`PaedsENGAGE GP Partner`}</b>
      </Text>
      <Text>{`${data.doctor.name} (MCR: ${data.doctor.mcr})`}</Text>
      <Text>{data.referringClinic.name}</Text>
      <Text>{formatAddress(data.referringClinic)}</Text>
      <Text
        fontStyle="italic"
        color="base.content.medium"
      >{`Referred on ${formatDate({ date: data.createdAt })}`}</Text>
    </VStack>
  )
}
