import { PublicReferralDto } from '~shared/dtos'
import {
  NhgdClinicalLabFasting,
  NhgdClinicalLabPurpose,
  NhgdClinicalLabTestType,
  NhgdClinicalRadiologyPurpose,
  NhgdCollectionMode,
  NhgdLastMenstrualPeriodReason,
  NhgdPaymentMode,
  NhgdPriority,
  NhgdReportMaterials,
} from '~shared/types'
import { isStringArray } from '~shared/utils/is-string-array'

export type NhgdCommonFields = {
  id: string
  patient: PublicReferralDto['patient']
  referringClinic: {
    name: string
    block: string
    streetName: string
    buildingName: string | null
    floorNumber: string | null
    unitNumber: string | null
    postalCode: string
  }
  doctor: {
    name: string
    mcr: string
  }
  // `Referring patient for specialized orthopedic evaluation. The patient reports persistent right knee pain, exacerbated by prolonged standing and climbing stairs. Previous treatments, including a course of ibuprofen and physical therapy, provided temporary relief but did not address the underlying issue. Observed limited range of motion and crepitus during physical examination. The pain significantly hinders daily activities, such as walking and bending.`
  note: string
  paymentMode?: NhgdPaymentMode
  collectionMode?: NhgdCollectionMode
  createdAt: string
  testCodes: string[]
}

export type NhgdLabFields = NhgdCommonFields & {
  purpose?: NhgdClinicalLabPurpose
  priority?: NhgdPriority
  fasting?: NhgdClinicalLabFasting
  testTypes: NhgdClinicalLabTestType[]
}

export type NhgdRadiologyFields = NhgdCommonFields & {
  purpose?: NhgdClinicalRadiologyPurpose
  reportMaterials?: NhgdReportMaterials
  lmpDate: string
  lmpReason?: NhgdLastMenstrualPeriodReason
}

const extractLabPurpose = (
  referral: PublicReferralDto,
): NhgdClinicalLabPurpose | undefined => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('purpose'),
  )?.answer

  if (typeof answer !== 'string') return undefined

  for (const purpose of Object.values(NhgdClinicalLabPurpose)) {
    if (answer === purpose) return purpose
  }

  return undefined
}

const extractRadiologyPurpose = (
  referral: PublicReferralDto,
): NhgdClinicalRadiologyPurpose | undefined => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('purpose'),
  )?.answer

  if (typeof answer !== 'string') return undefined

  for (const purpose of Object.values(NhgdClinicalRadiologyPurpose)) {
    if (answer === purpose) return purpose
  }

  return undefined
}

const extractPaymentMode = (
  referral: PublicReferralDto,
): NhgdPaymentMode | undefined => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('payment'),
  )?.answer

  if (typeof answer !== 'string') return undefined

  for (const paymentMode of Object.values(NhgdPaymentMode)) {
    if (answer === paymentMode) return paymentMode
  }

  return undefined
}

const extractReportMaterials = (
  referral: PublicReferralDto,
): NhgdReportMaterials | undefined => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('report'),
  )?.answer

  if (typeof answer !== 'string') return undefined

  for (const reportMaterials of Object.values(NhgdReportMaterials)) {
    if (answer === reportMaterials) return reportMaterials
  }

  return undefined
}

const extractCollectionMode = (
  referral: PublicReferralDto,
): NhgdCollectionMode | undefined => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('collection'),
  )?.answer

  if (typeof answer !== 'string') return undefined

  for (const collectionMode of Object.values(NhgdCollectionMode)) {
    if (answer === collectionMode) return collectionMode
  }

  return undefined
}

const extractPriority = (
  referral: PublicReferralDto,
): NhgdPriority | undefined => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('priority'),
  )?.answer

  if (typeof answer !== 'string') return undefined

  for (const priority of Object.values(NhgdPriority)) {
    if (answer === priority) return priority
  }

  return undefined
}

const extractNote = (referral: PublicReferralDto): string => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('referral notes'),
  )?.answer

  if (typeof answer !== 'string') return ''

  return answer
}

const extractTestCodes = (referral: PublicReferralDto): string[] => {
  let addons = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('add on'),
  )?.answer

  // Lab doesn't have add-ons, so safely ignore it
  if (!addons) addons = []

  const tests = referral.formResponses.find(({ question }) =>
    // to note: form responses have another key that has "type" in it (Test Type)
    // so we use `startsWith` here
    question.toLowerCase().startsWith('type'),
  )?.answer

  if (!isStringArray(addons) || !isStringArray(tests)) return []

  // This is tied to generate-phas-form-fields.ts
  return [...addons, ...tests].map((item) => item.split(' - ')[0])
}

const extractLastMenstrualPeriod = (referral: PublicReferralDto): string => {
  const lmpDate = referral.formResponses.find(({ question }) =>
    // Special case - 2 questions have the substring LMP, so we use endsWith
    question.toLowerCase().endsWith('last menstrual period'),
  )?.answer

  if (typeof lmpDate !== 'string' || lmpDate.length === 0) return 'NA'

  return lmpDate
}

const extractLastMenstrualPeriodReason = (
  referral: PublicReferralDto,
): NhgdLastMenstrualPeriodReason | undefined => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('if last menstrual period was >28 days'),
  )?.answer

  if (typeof answer !== 'string') return undefined

  for (const reason of Object.values(NhgdLastMenstrualPeriodReason)) {
    if (answer === reason) return reason
  }

  return undefined
}

const extractFasting = (
  referral: PublicReferralDto,
): NhgdClinicalLabFasting | undefined => {
  const fasting = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('fast'),
  )?.answer

  if (typeof fasting !== 'string') return undefined

  for (const value of Object.values(NhgdClinicalLabFasting)) {
    if (fasting === value) return fasting
  }

  return undefined
}

const extractTestTypes = (
  referral: PublicReferralDto,
): NhgdClinicalLabTestType[] => {
  const testTypes = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('test type'),
  )?.answer

  const testTypesSet = new Set<string>(Object.values(NhgdClinicalLabTestType))

  if (!isStringArray(testTypes)) return []

  return testTypes.filter((testType) =>
    testTypesSet.has(testType),
  ) as NhgdClinicalLabTestType[]
}

export const extractNhgdLabFields = (
  referral: PublicReferralDto,
): NhgdLabFields => {
  const purpose = extractLabPurpose(referral)
  const paymentMode = extractPaymentMode(referral)
  const collectionMode = extractCollectionMode(referral)
  const priority = extractPriority(referral)
  const note = extractNote(referral)
  const testCodes = extractTestCodes(referral)
  const testTypes = extractTestTypes(referral)
  const fasting = extractFasting(referral)

  return {
    id: referral.id,
    patient: referral.patient,
    referringClinic: {
      name: referral.sender.name,
      block: referral.sender.block,
      streetName: referral.sender.streetName,
      buildingName: referral.sender.buildingName,
      floorNumber: referral.sender.floorNumber,
      unitNumber: referral.sender.unitNumber,
      postalCode: referral.sender.postalCode,
    },
    doctor: {
      name: referral.referringDoctor.name,
      mcr: referral.referringDoctor.mcr,
    },
    note,
    purpose,
    paymentMode,
    collectionMode,
    priority,
    testCodes,
    createdAt: referral.createdAt,
    testTypes,
    fasting,
  }
}

export const extractNhgdRadiologyFields = (
  referral: PublicReferralDto,
): NhgdRadiologyFields => {
  const purpose = extractRadiologyPurpose(referral)
  const paymentMode = extractPaymentMode(referral)
  const reportMaterials = extractReportMaterials(referral)
  const collectionMode = extractCollectionMode(referral)
  const note = extractNote(referral)
  const testCodes = extractTestCodes(referral)
  const lmpDate = extractLastMenstrualPeriod(referral)
  const lmpReason = extractLastMenstrualPeriodReason(referral)

  return {
    id: referral.id,
    patient: referral.patient,
    referringClinic: {
      name: referral.sender.name,
      block: referral.sender.block,
      streetName: referral.sender.streetName,
      buildingName: referral.sender.buildingName,
      floorNumber: referral.sender.floorNumber,
      unitNumber: referral.sender.unitNumber,
      postalCode: referral.sender.postalCode,
    },
    doctor: {
      name: referral.referringDoctor.name,
      mcr: referral.referringDoctor.mcr,
    },
    note,
    purpose,
    paymentMode,
    reportMaterials,
    collectionMode,
    testCodes,
    lmpDate: lmpDate,
    lmpReason: lmpReason ?? NhgdLastMenstrualPeriodReason.Na,
    createdAt: referral.createdAt,
  }
}
