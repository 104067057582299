import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react'

import { GpBill } from './components/GpBill'
import { PaedsEngageLayout } from './components/PaedsEngageLayout'
import { PaedsEngageSentBy } from './components/PaedsEngageSentBy'
import { PaedsEngageTnC } from './components/PaedsEngageTnC'
import { extractPaedsEngageFields } from './extract-paeds-form-fields'
import { bodyStyle, headerStyle } from './paeds-layer-styles'

import { PublicReferralDto } from '~shared/dtos'

/** Priorities for this component is to be print-friendly */
export const PaedsEngageFormView = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  const data = extractPaedsEngageFields(referral)

  return (
    <VStack>
      <PaedsEngageLayout referralId={data.id}>
        <Flex>
          <Flex flexDir="column" width="100%" {...bodyStyle}>
            <Flex>
              <Text flex={1}>
                <b>{`Patient Name: `}</b>
                {data.patient.name}
              </Text>
              <Text flex={1}>
                <b>{`G6PD Deficiency: `}</b>
                {data.g6pdDeficiency}
              </Text>
              <Text flex={1}>
                <b>{`To: `}</b>
                {data.to}
              </Text>
            </Flex>
            <Flex>
              <Text flex={1}>
                <b>{`NRIC / FIN: `}</b>
                {data.patient.uin}
              </Text>
              <Text flex={2}>
                <b>{`Drug allergies: `}</b>
                {data.drugAllergies}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Divider borderColor="black" borderWidth="1px" />

        <Box width="50%" {...headerStyle}>
          {`Patient's Information`}
        </Box>
        <Text flex={1} {...bodyStyle}>
          <b>{`Summary of history, clinical findings and treatment administered: `}</b>
          {data.historyAndFindings}
        </Text>
        <Text flex={1} {...bodyStyle}>
          <b>{`Diagnosis: `}</b>
          {data.diagnosis}
        </Text>
        <Text flex={1} {...bodyStyle}>
          <b>{`Indication for referral: `}</b>
          {data.indicationForReferral}
        </Text>

        <Divider borderColor="black" borderWidth="1px" />

        <Flex>
          <PaedsEngageSentBy data={data} width="50%" />
          <GpBill gpBill={data.gpBill} width="50%" align="stretch" pt={6} />
        </Flex>
        <PaedsEngageTnC alignItems="start" mt={6} />
      </PaedsEngageLayout>
    </VStack>
  )
}
