import { useState } from 'react'
import { BiPrinter } from 'react-icons/bi'
import {
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'

import { ChasReferralFormView } from './chas/ChasReferralFormView'
import { DefaultReferralFormView } from './default'
import { NhgdLabFormView, NhgdRadiologyFormView } from './nhg'
import { PaedsEngageFormView } from './paeds-engage'
import { useZoomState, ZoomButtonGroup } from './ZoomButtonGroup'

import { HiddenPrintLayout } from '@/components/HiddenPrintLayout'
import { usePromptPrintDialogue } from '@/hooks/usePromptPrintDialogue'
import { PublicReferralDto } from '~shared/dtos'

export const PublicReferralViewFactory = ({
  referral,
  isPrintable = false,
  formTabIndex = 0,
  isPrintView = false,
}: {
  referral: PublicReferralDto
  isPrintable?: boolean
  formTabIndex?: number
  isPrintView?: boolean
}) => {
  // doctor notes form goes first
  const sortedForms = referral.forms?.sort((a, b) => {
    if (a.id === 'chas' || a.id === 'default') return -1
    if (b.id === 'chas' || b.id === 'default') return 1
    return 0
  })
  referral.forms = sortedForms

  const { zoom, onZoomOut, onZoomIn } = useZoomState()
  const [formId, setFormId] = useState(referral.forms?.[formTabIndex].id)
  const [tabIndex, setTabIndex] = useState(formTabIndex)
  const handleTabChange = (index: number) => {
    setFormId(referral.forms?.[index].id)
    setTabIndex(index)
  }
  const handlePrint = (isPrintable: boolean) => {
    if (isPrintable) {
      window.print()
    } else {
      window.open(
        `/referrals/${referral.id}?isPrintView=true&formTabIndex=${tabIndex}`,
        '_blank',
      )
    }
  }
  usePromptPrintDialogue({ enabled: isPrintView })

  return (
    <HiddenPrintLayout
      w="full"
      printOnlyChildren={
        <Flex
          w="fit-content"
          h="fit-content"
          bg="white"
          p="1.5rem"
          mx="auto"
          align="stretch"
        >
          <PublicReferralSingleFormView
            referral={{
              ...referral,
              formResponses:
                referral.forms?.find((form) => form.id === formId)
                  ?.formResponses ?? [],
            }}
            formId={formId}
          />
        </Flex>
      }
    >
      <Flex h="full" overflow="hidden">
        <Tabs isFitted width="full" onChange={handleTabChange} index={tabIndex}>
          <TabList>
            {referral.forms?.map((form) => (
              <Tab
                key={`tab-list-${form.id}`}
                px={4}
                py={2}
                whiteSpace="normal"
                height="auto"
              >
                {form.description}
              </Tab>
            ))}
          </TabList>
          <Flex
            bg="base.canvas.backdrop"
            gap={2}
            p={3}
            alignItems="center"
            justifyContent="center"
          >
            <ZoomButtonGroup
              onZoomOut={onZoomOut}
              onZoomIn={onZoomIn}
              size="xs"
            />
            <IconButton
              aria-label="Print"
              size="xs"
              variant="outline"
              colorScheme="neutral"
              onClick={() => handlePrint(isPrintable)}
              icon={<BiPrinter size={19} />}
            />
          </Flex>

          <TabPanels>
            {referral.forms?.map((form) => {
              const referralCopy = { ...referral }
              referralCopy.formResponses = form.formResponses
              return (
                <TabPanel key={`tab-panel-${form.id}`}>
                  <Flex
                    h="calc(100vh - 110px)"
                    flex="1"
                    bg="base.canvas.backdrop"
                    overflow="auto"
                    p="2rem"
                    pb="7rem"
                  >
                    <Flex
                      w="fit-content"
                      h="fit-content"
                      bg="white"
                      p="1.5rem"
                      mx="auto"
                      align="stretch"
                      style={{ zoom: `${zoom}%` }}
                    >
                      <PublicReferralSingleFormView
                        referral={referralCopy}
                        formId={formId}
                      />
                    </Flex>
                  </Flex>
                </TabPanel>
              )
            })}
          </TabPanels>
        </Tabs>
      </Flex>
    </HiddenPrintLayout>
  )
}

export const PublicReferralSingleFormView = ({
  referral,
  formId,
}: {
  referral: PublicReferralDto
  formId?: string
}) => {
  switch (formId) {
    case 'nhgd_laboratory_test':
    case 'nhgd_spirometry':
      return <NhgdLabFormView referral={referral} />

    case 'nhgd_xray':
    case 'nhgd_mammogram':
    case 'nhgd_ultrasound':
    case 'nhgd_bmd':
      return <NhgdRadiologyFormView referral={referral} />

    case 'chas':
      return <ChasReferralFormView referral={referral} />
    case 'paeds_engage':
      return <PaedsEngageFormView referral={referral} />

    case null: // back-compat
    case 'default':
    default:
      return <DefaultReferralFormView referral={referral} />
  }
}
