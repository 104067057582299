import { FormResponse } from '../dtos'

import { isStringArray } from './is-string-array'

type QuestionMatcher = string | RegExp

const matchesQuestion = (
  question: string,
  matcher: QuestionMatcher,
): boolean => {
  if (typeof matcher === 'string') {
    return question.toLowerCase().includes(matcher.toLowerCase())
  }
  return matcher.test(question)
}

export const extractStringByQuestion = ({
  questionFragment,
  formResponses,
}: {
  questionFragment: QuestionMatcher
  formResponses: FormResponse[]
}): string | undefined => {
  const answer = formResponses.find(({ question }) =>
    matchesQuestion(question, questionFragment),
  )?.answer

  if (typeof answer !== 'string') return undefined

  return answer
}

export const extractStringArrayByQuestion = ({
  questionFragment,
  formResponses,
}: {
  questionFragment: QuestionMatcher
  formResponses: FormResponse[]
}): string[] | undefined => {
  const answer = formResponses.find(({ question }) =>
    matchesQuestion(question, questionFragment),
  )?.answer

  if (!answer || !isStringArray(answer)) return undefined

  return answer
}
