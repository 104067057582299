import {
  Divider,
  Flex,
  HStack,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'

import { bodyStyle } from '../paeds-layer-styles'

/** Priorities for this component is to be print-friendly */
export const GpBill = ({
  gpBill,
  ...rest
}: { gpBill: string } & StackProps) => {
  return (
    <VStack {...bodyStyle} {...rest}>
      <Flex alignItems="start" justifyContent="space-between" gap={2}>
        <Text fontWeight="bold">GP Clinic Bill:</Text>
        <Flex p={1} flexDir="column" textAlign="center" flex={1} gap={1}>
          <Text minH="1rem">{gpBill}</Text>
          <Divider borderColor="black" />
          <HStack
            spacing={1}
            textStyle="caption-2"
            justifyContent="center"
            w="100%"
          >
            <Text as="span" textDecoration="line-through">
              with
            </Text>
            <Text>/without GST</Text>
          </HStack>
        </Flex>
      </Flex>
    </VStack>
  )
}
