import { Flex, Image, Text, VStack } from '@chakra-ui/react'

import NuhLogo from '@/assets/nuh-logo.png'

export const NuhLogoAddress = () => {
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      h="6.5rem"
    >
      <Image flexShrink={0} src={NuhLogo} width="10rem" />
      <VStack
        textStyle="caption-2"
        gap={0}
        fontSize="9px"
        align="start"
        lineHeight="0.75rem"
      >
        <Text>
          <b>{`NUH Children's Emergency (CE)`}</b>
        </Text>
        <Text>{`National University Hospital`}</Text>
        <Text>{`5 Lower Kent Ridge Road S119074`}</Text>
      </VStack>
    </Flex>
  )
}
