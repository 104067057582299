import { useMemo } from 'react'
import { Flex, FlexProps, Image, Spacer, Text, VStack } from '@chakra-ui/react'

import { KkhLogoAddress } from './KkhLogoAddress'
import { NuhLogoAddress } from './NuhLogoAddress'

import PaedsEngageLogo from '@/assets/paeds-engage-logo.png'

export type PaedsEngageLayoutProps = FlexProps & {
  referralId: string
}

const PRINT_HEADER_CLASS_NAME = 'print-header'

export const PaedsEngageLayout = ({
  referralId,
  children,
  ...rest
}: PaedsEngageLayoutProps) => {
  // Visiually highlight just 8 digits for easier reading
  // relies on UUID format including dashes
  const { prefix, suffix } = useMemo(() => {
    const prefix = referralId.slice(0, 8)
    const suffix = referralId.slice(9) // skip first dash
    return { prefix, suffix }
  }, [referralId])

  const printStyles = `
    @media print {
      @page {
        size: A4;
        margin: 0;
      }
      .${PRINT_HEADER_CLASS_NAME} {
        display: flex;
      }
      body {
        margin: 0;
        padding: 0;
      }
    }`

  return (
    <Flex
      px={10}
      w="full"
      alignItems="stretch"
      flexShrink={0}
      maxWidth="210mm"
      width="210mm"
      mx="auto"
      flexDir="column"
      py={4}
      gap={2}
      style={{
        breakBefore: 'always',
        breakInside: 'avoid',
        minHeight: '297mm',
      }}
      {...rest}
    >
      <style>{printStyles}</style>
      <Flex className={PRINT_HEADER_CLASS_NAME} alignItems="center" gap={4}>
        <Image flexShrink={0} src={PaedsEngageLogo} width="8rem" />
        <Spacer flex={10} h="1px" />
        <KkhLogoAddress />
        <NuhLogoAddress />
        <VStack textTransform="uppercase" align="center" textAlign="center">
          <Text textStyle="h4" color="interaction.critical.default">
            {prefix}
          </Text>
          <Text textStyle="caption-1">{suffix}</Text>
        </VStack>
      </Flex>
      <Flex justifyContent="center" flexDir="column" gap={0} textAlign="center">
        <Text
          textTransform="uppercase"
          color="interaction.critical.default"
          textStyle="body-2"
          fontWeight="medium"
        >{`Please bring along this hardcopy form`}</Text>
        <Flex
          textAlign="center"
          justifyContent="center"
          width="100%"
          bg="grey.500"
          py={2}
          px={6}
          color="white"
          textStyle="caption-1"
          style={{
            WebkitPrintColorAdjust: 'exact',
            printColorAdjust: 'exact',
          }}
        >
          <Text>{`As the referring doctor, I have discussed and agreed with the patient's parent or next-of-kin on the appropriate venue for A&E treatment as indicated above.`}</Text>
        </Flex>
      </Flex>
      {children}
    </Flex>
  )
}
