export const headerStyle = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: '14px',
} as const

export const bodyStyle = {
  fontSize: '12px',
  textStyle: 'body-2',
} as const

export const borders = {
  py: 1,
  px: 2,
  // We cheat by using half the desired width, so that borders no-overlap cleanly
  // Outer borders are affected (actually show 0.5px)
  // which is why the overall component needs an additional prop "border: 1px"
  boxShadow: '0 0 0 0.5px black',
} as const
