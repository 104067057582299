import { useMutation } from '@tanstack/react-query'

import { queryKeys } from '@/constants/query-keys'
import { ApiService } from '@/services'
import { queryClient } from '@/utils/query'
import {
  EaConfirmReferralBody,
  EaRejectReferralBody,
  ReferralDto,
} from '~shared/dtos'

export const useAcknowledgeReferralWithAcceptance = () => {
  return useMutation({
    mutationFn: async ({
      referralId,
      token,
      ...body
    }: {
      referralId?: string
      token: string | null
    } & EaConfirmReferralBody) => {
      const data = await ApiService.extend({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .post(`v1/referrals/${referralId}/accept`, {
          json: body,
        })
        .json<ReferralDto>()
      return data
    },
    onSuccess: async (_, { referralId }) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.referral(referralId ?? ''),
      })
    },
  })
}

export const useAcknowledgeReferralWithRejection = () => {
  return useMutation({
    mutationFn: async ({
      referralId,
      rejectionMessage,
      token,
    }: {
      referralId?: string
      token: string | null
    } & EaRejectReferralBody) => {
      const data = await ApiService.extend({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .post(`v1/referrals/${referralId}/reject`, {
          json: { rejectionMessage },
        })
        .json<ReferralDto>()
      return data
    },
    onSuccess: async (_, { referralId }) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.referral(referralId ?? ''),
      })
    },
  })
}
