import {
  ListItem,
  OrderedList,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'

import { bodyStyle, headerStyle } from '../paeds-layer-styles'

/** Priorities for this component is to be print-friendly */
export const PaedsEngageTnC = (props: StackProps) => {
  return (
    <VStack {...bodyStyle} {...props}>
      <Text {...headerStyle}>Terms and Conditions</Text>
      <OrderedList>
        <ListItem>
          {`Referral of patients through this partnership must be made electronically via the National Referral Program OR via the original and serialised hardcopy PaedsENGAGE Referral Form provided by KKH and NUH`}
        </ListItem>
        <ListItem>
          {`The original Referral Form must be duly completed with the participating clinic's details. The parent/next-of-kin must produce the completed Referral Form at KKH or NUH CE together with the patient's Birth Certificate for verification.`}
        </ListItem>
        <ListItem>
          {`It is the responsibility of the parent/next-of-kin to bring both patient AND the original copy of the referral form to CE within `}
          <Text
            as="span"
            fontWeight="bold"
            color="interaction.critical.default"
          >
            12 hours from date/time of referral
          </Text>
          .
        </ListItem>
        <ListItem>
          {`Patients will receive a $50 subsidy on the prevailing charges when referred to KKH or NUH CE by participating CPs.`}
        </ListItem>
        <ListItem>
          {`Specialised investigations and medications e.g. sedation required at CE will be separately charged.`}
        </ListItem>
      </OrderedList>
    </VStack>
  )
}
