import { PublicReferralDto } from '~shared/dtos'

export type PaedsEngageFields = {
  id: string
  patient: PublicReferralDto['patient']
  referringClinic: {
    name: string
    block: string
    streetName: string
    buildingName: string | null
    floorNumber: string | null
    unitNumber: string | null
    postalCode: string
  }
  doctor: {
    name: string
    mcr: string
  }
  g6pdDeficiency: 'Yes' | 'No' | 'Unknown'
  historyAndFindings: string
  diagnosis: string
  drugAllergies: string
  indicationForReferral: string
  gpBill: string
  createdAt: string
  to: string
}

const extractHistoryAndFindings = (referral: PublicReferralDto): string => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('clinical findings'),
  )?.answer

  if (typeof answer !== 'string') return ''

  return answer
}

const extractDiagnosis = (referral: PublicReferralDto): string => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('diagnosis'),
  )?.answer

  if (typeof answer !== 'string') return ''

  return answer
}

const extractIndicationForReferral = (referral: PublicReferralDto): string => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('indication for referral'),
  )?.answer

  if (typeof answer !== 'string') return ''

  return answer
}

const extractGpBillDollars = (referral: PublicReferralDto): string => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('clinic bill'),
  )?.answer

  if (!answer) return ''

  if (typeof answer === 'string') {
    const parsed = parseFloat(answer)
    if (!isNaN(parsed)) {
      return `$${parsed.toFixed(2)}`
    }
    return answer
  }

  if (typeof answer === 'number') {
    return `$${answer.toFixed(2)}`
  }

  return ''
}

const extractDrugAllergies = (referral: PublicReferralDto): string => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('drug allergies'),
  )?.answer

  if (typeof answer !== 'string') return ''

  return answer
}

const extractG6pdDeficiency = (
  referral: PublicReferralDto,
): 'Yes' | 'No' | 'Unknown' => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes('g6pd deficiency'),
  )?.answer

  if (typeof answer !== 'string') return 'Unknown'

  if (answer === 'Yes') return 'Yes'
  if (answer === 'No') return 'No'

  return 'Unknown'
}

export const extractPaedsEngageFields = (
  referral: PublicReferralDto,
): PaedsEngageFields => {
  const historyAndFindings = extractHistoryAndFindings(referral)
  const diagnosis = extractDiagnosis(referral)
  const indicationForReferral = extractIndicationForReferral(referral)
  const gpBill = extractGpBillDollars(referral)
  const g6pdDeficiency = extractG6pdDeficiency(referral)
  const drugAllergies = extractDrugAllergies(referral)

  return {
    id: referral.id,
    patient: referral.patient,
    referringClinic: {
      name: referral.sender.name,
      block: referral.sender.block,
      streetName: referral.sender.streetName,
      buildingName: referral.sender.buildingName,
      floorNumber: referral.sender.floorNumber,
      unitNumber: referral.sender.unitNumber,
      postalCode: referral.sender.postalCode,
    },
    doctor: {
      name: referral.referringDoctor.name,
      mcr: referral.referringDoctor.mcr,
    },
    g6pdDeficiency,
    historyAndFindings,
    diagnosis,
    drugAllergies,
    indicationForReferral,
    gpBill,
    createdAt: referral.createdAt,
    to: referral.offering.institution.name,
  }
}
