import { Controller, useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import {
  DatePicker,
  FormErrorMessage,
  FormLabel,
  SingleSelect,
} from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { EaFormFields, requiredRule } from '../../email-ack.types'
import { generateTimeOptions } from '../../email-ack.utils'

export const DateTimeField = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<EaFormFields>()

  const date = watch('date')
  const time = watch('time')
  const isInPast = dayjs(date).isBefore(dayjs(), 'day')
  const isNotStandardHours =
    (time &&
      dayjs(
        `${dayjs(date).format('YYYY-MM-DD')} ${time}`,
        'YYYY-MM-DD h:mm a',
      ).hour() < 7) ||
    dayjs(
      `${dayjs(date).format('YYYY-MM-DD')} ${time}`,
      'YYYY-MM-DD h:mm a',
    ).hour() > 18

  return (
    <>
      <Text textStyle="subhead-1" color="base.content.strong">
        Appointment scheduled on
      </Text>
      <Wrap w="full">
        <WrapItem flexGrow={1} minWidth="0">
          <FormControl isInvalid={!!errors.date}>
            <FormLabel isRequired>Date</FormLabel>
            <Controller
              name="date"
              rules={requiredRule}
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  isDateUnavailable={(d) => dayjs(d).isBefore(dayjs(), 'd')}
                  showTodayButton={false}
                />
              )}
            />
            {isInPast && (
              <FormHelperText color="utility.feedback.critical" pt={2}>
                Date selected is in the past. Ensure that it is accurate before
                submitting.
              </FormHelperText>
            )}
            <FormErrorMessage>{errors.date?.message}</FormErrorMessage>
          </FormControl>
        </WrapItem>
        <WrapItem flexGrow={2} minWidth="0">
          <FormControl isInvalid={!!errors.time}>
            <FormLabel isRequired>Time</FormLabel>
            <Controller
              name="time"
              rules={requiredRule}
              control={control}
              render={({ field: { onChange, value } }) => (
                <SingleSelect
                  name="time"
                  colorScheme="gray"
                  placeholder="Select Time"
                  items={generateTimeOptions()}
                  value={value}
                  onChange={onChange}
                  size="md"
                  isClearable={false}
                />
              )}
            />
            {isNotStandardHours && (
              <FormHelperText color="utility.feedback.critical" pt={2}>
                This timing is outside standard clinic hours. Please check again
                before proceeding.
              </FormHelperText>
            )}
            <FormErrorMessage>{errors.time?.message}</FormErrorMessage>
          </FormControl>
        </WrapItem>
      </Wrap>
    </>
  )
}
