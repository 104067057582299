import { Flex, Image, Text, VStack } from '@chakra-ui/react'

import KkhLogo from '@/assets/kkh-logo.png'

export const KkhLogoAddress = () => {
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      h="6.5rem"
    >
      <Image flexShrink={0} src={KkhLogo} width="10rem" />
      <VStack
        textStyle="caption-2"
        gap={0}
        fontSize="9px"
        align="start"
        lineHeight="0.75rem"
      >
        <Text>
          <b>{`KKH Children's Emergency (CE)`}</b>
        </Text>
        <Text>{`KK Women's and Chidlren's Hospital`}</Text>
        <Text>{`100 Bukit Timah Road S229899`}</Text>
      </VStack>
    </Flex>
  )
}
